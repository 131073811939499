
.stacktable { width: 100%; }
.st-head-row { padding-top: 1em; }
.st-head-row.st-head-row-main { font-size: 1.5em; padding-top: 0; }
.st-key { width: 40%; text-align: left; padding-right: 1%; font-size: 0.95rem; }
.st-val { width: 57%; padding-left: 1%; vertical-align: initial; font-size: 0.95rem}

/* RESPONSIVE EXAMPLE */

.stacktable.large-only { display: table; }
.stacktable.small-only { display: none; }

@media (max-width: 800px) {
  .stacktable.large-only { display: none; }
  .stacktable.small-only { display: table; }
}


// Specifiek voor Beaulieu
        /* top-left border-radius */
        table.stackcolumns tr:first-child th:first-child {
            border-top-left-radius: 5px;
            border-top: none;
            border-left: none;
          }
          
          /* top-right border-radius */
          table.stackcolumns tr:first-child th:last-child {
            border-top-right-radius: 5px;
            border-top: none;
            border-right: none;
          }
          
          /* bottom-left border-radius */
          table.stackcolumns tr:last-child td:first-child,
          table.stackcolumns tr:last-child th:first-child {
            border-bottom-left-radius: 5px;
            border-bottom: none !important;
            border-left: none;
          }
          
          /* bottom-right border-radius */
          table.stackcolumns tr:last-child td:last-child {
            border-bottom-right-radius: 5px;
            border-bottom: none;
            border-right: none;
          }

          .table-bordered{
              border: none;
              td, th {
                border-top: none;
                border-right: 1px solid #dee2e6;
                border-bottom: 1px solid #dee2e6;
                border-left: none;
                }
                tr td:last-child{
                    border-right: none;
                }
                tr:last-child td{
                    border-bottom: none;
                }
          }



.stacktable tbody .st-head-row:first-child{
    margin-top: 0;
}
.stacktable tr{
    th, td{
        border-top: 1px solid #dee2e6;
        padding: .5rem;
    }
}
.stacktable tr:nth-of-type(odd){
  background-color: #F6F6F6;
}
.stacktable tr:nth-of-type(even){
  background-color: #ffffff;
}
.stacktable {
  position: relative;
  tr:first-child{
      background-color: #cdcdcd;
  }
}   

.st-head-row{
    display: flex; // herschik de titels zodat deze links gelijnd worden
    margin-top: 2rem;
    margin-bottom: 35px;
    .st-key{
        width: 0;
        padding: 0;
    }
    .st-val{
        position: absolute;
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-top: none;
        padding: .6rem;
        line-height: normal;
        background-color: #cdcdcd;
    }
}
