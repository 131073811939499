body{
    overflow-x: hidden;
}
main{
    padding-bottom: 2rem;
}
.container{
    padding-top: 2rem;
    padding-bottom: 2rem;
}

@include media-breakpoint-down(sm) {
    .container{
        padding-top: 1rem;
        padding-bottom: 1rem;
        .container-sm, .container-md, .container-lg{
            padding-left: 0;
            padding-right: 0; 
        }
    }  
}

@include media-breakpoint-down(md) {
    .container{
        .container-sm, .container-md, .container-lg{
            padding-left: 0;
            padding-right: 0;
            max-width: none;
        }
    }  
}
@include media-breakpoint-up(xl) {
    .container{
        max-width: 1100px;
    }
    .container-lg{ // can be nested in .container of separate
        max-width: 1300px;
    }
    .container-md{ // can be nested in .container of separate
        max-width: 1010px;
    }
    .container-sm{ // can be nested in .container of separate
        max-width: 920px;
    }
}
@include media-breakpoint-down(md){
    .container{
        max-width: none;
    }
}
.intro-bg-grey, .intro-bg-white{
    margin-top: 1.5rem;
    border-radius: 5px;
    text-align: center;
}
.intro-bg-grey{
    background-color: $color4;
    padding: 2.5rem 5rem;
}
.intro-bg-white{
    background-color: $white;
    padding: 0 5rem;
}
@include media-breakpoint-only(xs) {
    .intro-bg-grey, .intro-bg-white{
        margin-top: 0.5rem;
    }
    .intro-bg-grey{
        padding: 1.5rem;
    }
    .intro-bg-white{
        padding: 0.5rem;
    }
}
.logos{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &.justified{
        justify-content: space-between;
    }
    > div{
        text-align: center;
    }
}
@include media-breakpoint-only(xs) {
    .logos{
        &.justified{
            justify-content: center;
        }
        > div{
            flex-basis:50%;
            padding: .5rem;
        }
    }
}


// INDUSTRY DETAIL
.table{
    th, td{
        text-align: center;
        border-color: #E2E5E6;
        color: #575757;
    }
    tr th:first-child{
        text-align: left;
    }
    tr th{
        text-transform: uppercase;
        font-size: 0.9rem;
        padding: .9rem;
    }
    tr:first-child th{
        border-bottom-color: #cdcdcd; 
    }
    tr:nth-child(2) th, tr:nth-child(2) td{
        border-top: none;
    }
    &.table-striped tr:nth-of-type(odd){
        background-color: #F6F6F6;
    }
    &.table-striped tr:nth-of-type(even){
        background-color: #ffffff;
    }
    &.table-striped  {
        tr:first-child{
            background-color: #cdcdcd;
        }
    }    
}

// MEDIA ROW (voorlopig enkel voor Certificates
.media--item{
    display: flex;
    margin-bottom: 5rem;
    padding-bottom: 5rem;
    border-bottom: 1px solid #ccc;
    &:last-child{
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    div:first-child{
        flex: 1;
        padding-right: 40px;
    }
    div:last-child{
        flex: 2;
        padding-left: 40px;
    }
    @include media-breakpoint-only(xs) {
        flex-direction: column;
        > div{
            &:first-child{
                margin-bottom: 2rem;
                padding: 0 80px;
            }
            &:last-child{
                padding: 0 15px
            }
        }
    }
}

// TEMPLATE CODE FOR MEDIA QUERIES
//@include media-breakpoint-up(xs) {}
//@include media-breakpoint-up(sm) {}
//@include media-breakpoint-up(md) {}
//@include media-breakpoint-up(lg) {}
//@include media-breakpoint-up(xl) {}


//@include media-breakpoint-down(xs) {}
//@include media-breakpoint-down(sm) {}
//@include media-breakpoint-down(md) {}
//@include media-breakpoint-down(lg) {}
// No media query necessary for xl breakpoint as it has no upper bound on its width


//@include media-breakpoint-only(xs) {}
//@include media-breakpoint-only(sm) {}
//@include media-breakpoint-only(md) {}
//@include media-breakpoint-only(lg) {}
//@include media-breakpoint-only(xl) {}
