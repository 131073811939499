body{
    font-family: droid-sans, sans-serif;
    font-weight: 400; // 400, 700
    font-style: normal;
    font-size: 16px;
    color: $color1;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: neo-sans, sans-serif; // 300, 400, 500, 700 (300 voor titles light : 400 voor baseline logo)
    font-weight: 500;
    margin-bottom: 1.4rem; 
    small{
        display: block;
        // font-style: oblique;
    }
}
h1, .h1{font-size: 3.0rem;}
h2, .h2{font-size: 1.8rem;}
h3, .h3{font-size: 1.6rem;}
h4, .h4{font-size: 1.4rem;}

@include media-breakpoint-down(sm) {
    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
        margin-bottom: 0.8rem;
    }
    h1, .h1{font-size: 1.8rem;}
    h2, .h2{font-size: 1.6rem;}
    h3, .h3{font-size: 1.4rem;}
    h4, .h4{font-size: 1.2rem;}
}

.titles-light{
    font-weight: 300;
    h1 , .h1, h2, .h2, h3, .h3, h4, .h4{        
        font-weight: 300;
    }
    h2{
         font-size: 1.8rem;
    }
}

p{
    margin-bottom: 1.5rem;
}
@include media-breakpoint-down(sm) {
    p{
        margin-bottom: 1rem;
    }
}
main > section .intro h1{
    margin-top: 0;
}
.bold, strong{
    font-weight: 700;
}
a{
    color: $color1;
    text-decoration: underline;
    &.nounderline{
        text-decoration: none;
    }
    &:hover{
        color: $color2;
        text-decoration: underline;
    }
    &.text-muted{
        color:$gray-500;
        font-size: 0.95rem;
    }
}
ul{
    padding-left: 20px;
}
.fw-400{
    font-weight: 400;
}

// UL WITH CHECK MARKS
.list-item-checkmark {
    padding: 0;
    li {
        display: flex;
        margin-bottom: 1rem;
        font-weight: 500;
        &:before {
            content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAOCAYAAAAi2ky3AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD9SURBVHgBpZLJjcJAEEVfW8ydDAYysAczZxwJzgQTCSISCMASZOB2BHBnaaq8gEEGhPlSS73p1fYNXyjD7xt+Nrr3+EICWYEbwLk7KGe0EIgvyzpOUY8OyglnDmKB7BUyZGv5VAqxhE6X9Mini3L+pzfIOG6+Gcs4043jEEiK+2cQjW7obcq/zIekSfPd045r5+XT6gXk+t4GKUDaLO287P1yEm0QHTN9h1u2QYrSGhE1bTEYyS/pvLqvDKdeYTsgDXiiwkc6PoeJqtQTnUwZpTaceuUY8ULmvoy/2ODV5a1lTWrDvfOKebzICBO5nJWnwnBBJ8PVMMto94nhLrcxbKkny9GCAAAAAElFTkSuQmCC');
            vertical-align: middle;
            margin-right: 9px;
            margin-top: 5px;
            line-height: 0;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }

}



// BREADCRUMB
.breadcrumb{
    padding: 0;
    background-color: white;
    a{
        color: $color2;
        text-decoration: none;
        &:after{
            content: '';
            display: inline-block;
            background: no-repeat center url('../..\assets/images/angle-right-red.svg');
            // background-size: 6px ;
            margin: 0 8px;
            height: 10px;
            width: 10px;
            // transform: scale(0.5);
        }
    }
}

