header{
	background: $greyGradient;
    position: fixed;
    z-index: 990; // 997 is izimodal voor cookieconstent + .floatingphone = 989
	top: 0;
	width: 100%;
    transition: all 0.4s ease;
	.container{
		padding-top: .7rem;
		padding-bottom: .8rem;
	}
	.d-flex{
		align-items: center;
	}
	&.shrink{
		box-shadow: 0 5px 5px rgba($color: $color1, $alpha: 0.1);
		.d-flex{
			align-items: flex-end;
		}
		// .search-open{
		// 	background: url('../../assets/images/loupe-white.svg') no-repeat center;
		// }
	}
    .logo{
		transition: all 0.4s ease-in-out;
		a{ text-decoration: none;}
        img{
			width: 130px;
			// height: 84px;
			height: 108px;
        }
		span{
			display: block;
			font-family: neo-sans, sans-serif; // 700, 900
			font-weight: 400;
			color: $color2;
		}
    }
}
@media only print {
    header {
        position: inherit;
    }
	main, #main{
		margin-top: 0 !important;
	}
	.btn{
		display: none;
	}
}
@include media-breakpoint-up(sm) {
	header{
		// .p-nav__top{transition: all 0.4s ease-in-out;}
		&.shrink{
			// margin-top: -25px;
			// .p-nav__top{
			// 	margin-bottom: 0.4rem;
			// }
			// .cntr_header{
			// 	margin-bottom: 1rem;;
			// }
		}
	}
}
// @include media-breakpoint-up(lg) {
// 	header{
// 		&.shrink{
//             .logo{
// 				margin-top: 15px;
// 			}
// 		}
// 	}
// }
@include media-breakpoint-up(md) {
	header{
		&.shrink{
            .logo{
                // transform: scale(0.8);
				// margin-left: -10px;
					// margin-bottom: -5px;
					// img{
					// 	width: 62px;
					// 	height: 40px;
					// }
            }
            .container{
				// padding-top: 3.0rem;
                	// padding-bottom: 0rem;
            }
			.logo{
				// margin-bottom: -2.5rem;
					// span{
					// 	opacity: 0;
					// }
			}
		}
	}
	body.bg-md header,
	body.bg-lg header{
		&.shrink{
			background: $color1 !important;
		}
	}
}
@include media-breakpoint-down(md) {
	header{
		&.shrink{
			.container{
				padding-top: 2.5rem;
			}
            // .logo{
            //     transform: scale(0.6);
			// 	margin-left: 0;
			// 	margin-bottom: -2.8rem;
            // }
		}
		.container{
			padding-top: 0.5rem;
			padding-bottom: 0.7rem;
		}
		.d-flex{
			align-items: flex-start;
		}
		.logo{
			position: relative;
			z-index: 9999;
			img{
				width: 100px;
				height: 65px;
			}
			span{
				font-size: 0.8rem;
				font-weight: 300;
				letter-spacing: 0.5px;
			}
		}
	}
}
@include media-breakpoint-only(xs) {
	header{
		.container{
			padding-top: .5rem;
			padding-bottom: .5rem;
		}
		&.shrink{
			margin-top: 0 !important;
			.container{
				padding-top: .5rem;
			}
			.logo{
				transform: scale(1);
				margin-left: 0;
				margin-bottom: 0;
			}
		}
	}
}
