// BUTTONS
.btn-row{
    .btn{
        margin-right: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}
@include media-breakpoint-down(md) {
    .btn-row{
        margin-top: 1.5rem;
        text-align: center;
        .btn{
            margin-right: 1rem;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }   
}
.btn{
    border-radius: 5px;
    padding: .45rem .9rem;
    text-decoration: none;
    &:hover{
        color: $color2;
    }
    &.btn-lg{
        font-size: inherit;
        padding: .8rem .75rem;
    }
    &:focus{
        outline: none;
        box-shadow: none;
    }
}
.btn-theme{
    border-color: $color2;
    background-color: $color2;
    color: white !important;
    &:hover{
        border-color: darken($color2,6%);
        background-color: darken($color2,6%);
        text-decoration: none !important;
    }
}
.btn-dark{
    border-color: $color1;
    background-color: $color1;
    color: white !important;
    &:hover{
        border-color: darken($color2,6%);
        background-color: darken($color2,6%);
        text-decoration: none !important;
    }
}
.btn-outline{
    border-color: $color1;
    background-color: transparent;
    color: $color1 !important;
    font-weight: 700;
    &:hover{
        border-color:$color2;
        color: $color2 !important;
        text-decoration: none !important;
    }
}
.btn-download{
    display: flex;
    align-items: center;
    justify-content: center;
    &:before{
        content: '';
        width: 24px;
        height: 24px;
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGQ9Ik04LjUgMTcuNUwxMi41IDIxLjVMMTYuNSAxNy41IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTIuNSAxMi41VjIxLjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0yMS4zOCAxOC41ODk5QzIyLjI0OTQgMTcuOTc4NiAyMi45MDE0IDE3LjEwNjEgMjMuMjQxMyAxNi4wOTkxQzIzLjU4MTIgMTUuMDkyMSAyMy41OTE0IDE0LjAwMyAyMy4yNzA0IDEyLjk4OThDMjIuOTQ5NCAxMS45NzY2IDIyLjMxMzkgMTEuMDkyIDIxLjQ1NjEgMTAuNDY0NUMyMC41OTgzIDkuODM2OTcgMTkuNTYyOCA5LjQ5OTEgMTguNSA5LjQ5OTkzSDE3LjI0QzE2LjkzOTIgOC4zMjc4MSAxNi4zNzY1IDcuMjM5MTggMTUuNTk0MSA2LjMxNjAxQzE0LjgxMTcgNS4zOTI4NSAxMy44MzAxIDQuNjU5MTkgMTIuNzIzMiA0LjE3MDI5QzExLjYxNjMgMy42ODEzOCAxMC40MTI4IDMuNDQ5OTYgOS4yMDM1MiAzLjQ5MzQ1QzcuOTk0MiAzLjUzNjk0IDYuODEwNTEgMy44NTQyIDUuNzQxNTUgNC40MjEzNkM0LjY3MjU5IDQuOTg4NTEgMy43NDYyMiA1Ljc5MDc4IDMuMDMyMTggNi43Njc3NkMyLjMxODE0IDcuNzQ0NzQgMS44MzUwNSA4Ljg3MDk4IDEuNjE5MjUgMTAuMDYxN0MxLjQwMzQ2IDExLjI1MjQgMS40NjA2IDEyLjQ3NjUgMS43ODYzNyAxMy42NDJDMi4xMTIxNCAxNC44MDc0IDIuNjk4MDUgMTUuODgzNyAzLjQ5OTk5IDE2Ljc4OTkiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjUgMC41KSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=');
        margin-right: 0.5rem;
    }
    &-top{
        display: flex;
    }
}
.btn-filter{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .7rem .9rem;
    border:0 !important;
    border-radius: 3px !important;
    &:after{
        display: inline-block;
        content: '';
        width: 18px;
        height: 18px;
        opacity: 0.8;
        // background: no-repeat center url('data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiB2aWV3Qm94PSIwIDAgMjAgMjAiPgo8dGl0bGU+ZmlsdGVyPC90aXRsZT4KPHBhdGggZmlsbD0iIzYxNjM2NSIgZD0iTTEwIDBjLTUuNTIzIDAtMTAgMS4zOTktMTAgMy4xMjV2MS44NzVsNy41IDcuNXY2LjI1YzAgMC42OSAxLjExOSAxLjI1IDIuNSAxLjI1czIuNS0wLjU2IDIuNS0xLjI1di02LjI1bDcuNS03LjV2LTEuODc1YzAtMS43MjYtNC40NzctMy4xMjUtMTAtMy4xMjV6TTEuODQzIDIuNzExYzAuNDY4LTAuMjY3IDEuMTI1LTAuNTIgMS45LTAuNzMyIDEuNzE4LTAuNDcgMy45NC0wLjcyOSA2LjI1Ny0wLjcyOXM0LjUzOSAwLjI1OSA2LjI1NyAwLjcyOWMwLjc3NSAwLjIxMiAxLjQzMiAwLjQ2NSAxLjkgMC43MzIgMC4zMDggMC4xNzYgMC40NzUgMC4zMjUgMC41NTMgMC40MTQtMC4wNzggMC4wODktMC4yNDQgMC4yMzgtMC41NTMgMC40MTQtMC40NjggMC4yNjctMS4xMjUgMC41Mi0xLjkgMC43MzItMS43MTggMC40Ny0zLjk0IDAuNzI5LTYuMjU3IDAuNzI5cy00LjUzOS0wLjI1OS02LjI1Ny0wLjcyOWMtMC43NzUtMC4yMTItMS40MzItMC40NjUtMS45LTAuNzMyLTAuMzA4LTAuMTc2LTAuNDc1LTAuMzI1LTAuNTUzLTAuNDE0IDAuMDc4LTAuMDg5IDAuMjQ0LTAuMjM4IDAuNTUzLTAuNDE0eiI+PC9wYXRoPgo8L3N2Zz4K');
        // background: no-repeat center url('data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiB2aWV3Qm94PSIwIDAgMjAgMjAiPgo8dGl0bGU+ZmlsdGVyPC90aXRsZT4KPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTEwIDBjLTUuNTIzIDAtMTAgMS4zOTktMTAgMy4xMjV2MS44NzVsNy41IDcuNXY2LjI1YzAgMC42OSAxLjExOSAxLjI1IDIuNSAxLjI1czIuNS0wLjU2IDIuNS0xLjI1di02LjI1bDcuNS03LjV2LTEuODc1YzAtMS43MjYtNC40NzctMy4xMjUtMTAtMy4xMjV6TTEuODQzIDIuNzExYzAuNDY4LTAuMjY3IDEuMTI1LTAuNTIgMS45LTAuNzMyIDEuNzE4LTAuNDcgMy45NC0wLjcyOSA2LjI1Ny0wLjcyOXM0LjUzOSAwLjI1OSA2LjI1NyAwLjcyOWMwLjc3NSAwLjIxMiAxLjQzMiAwLjQ2NSAxLjkgMC43MzIgMC4zMDggMC4xNzYgMC40NzUgMC4zMjUgMC41NTMgMC40MTQtMC4wNzggMC4wODktMC4yNDQgMC4yMzgtMC41NTMgMC40MTQtMC40NjggMC4yNjctMS4xMjUgMC41Mi0xLjkgMC43MzItMS43MTggMC40Ny0zLjk0IDAuNzI5LTYuMjU3IDAuNzI5cy00LjUzOS0wLjI1OS02LjI1Ny0wLjcyOWMtMC43NzUtMC4yMTItMS40MzItMC40NjUtMS45LTAuNzMyLTAuMzA4LTAuMTc2LTAuNDc1LTAuMzI1LTAuNTUzLTAuNDE0IDAuMDc4LTAuMDg5IDAuMjQ0LTAuMjM4IDAuNTUzLTAuNDE0eiI+PC9wYXRoPgo8L3N2Zz4K'); 
        background: no-repeat center url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZGF0YS10YWdzPSJmaWx0ZXIiPjxwYXRoIGQ9Ik0xOS44NDUgNGwtNi42MDkgNy44MTRhLjk5NS45OTUgMCAwMC0uMjM2LjY0NnY2LjkyMmwtMi0xVjEyLjQ2YS45OTYuOTk2IDAgMDAtLjIzNi0uNjQ2TDQuMTU1IDR6TTIyIDJIMmExIDEgMCAwMC0uNzY0IDEuNjQ2TDkgMTIuODI2VjE5YTEgMSAwIDAwLjU1My44OTRsNCAyQTEgMSAwIDAwMTUgMjF2LTguMTc0bDcuNzY0LTkuMThBLjk5OS45OTkgMCAwMDIyIDJ6IiBmaWxsPSIjZmZmZmZmIi8+PC9zdmc+');
        background-size: contain;
    }
    &:hover{
        background-color: $color1 !important;
        border-color: $color1;
        color: $white !important;
    }
}
.btn-floating{
    display: inline-block;
}
@include media-breakpoint-only(xs) {
    #btn-floating.sticky {
        float: none;
        position: fixed;
        width: calc(100% - 30px);
        bottom: 5%;
        z-index: 9999;
        left: auto;
    }
    .btn-download{
        &-top{
            display: none;
        }
        &-bottom{
            padding: .7rem .9rem;
        }
    }
}
.btn-group{
    .btn-outline{
        font-weight: 400;
        padding: .44rem .9rem;
        &:hover{
            background-color: #eee;
            border-color:$color1;
            color: $color1 !important;
        }
        &:focus, &.active{
            box-shadow: none;
            background-color: $color1;
            color: $white !important;
        }
    }
}
.btn-angle-right{
    display: flex;
    align-items: center;
    text-align: left;
    font-weight: 700;
    color: #575757;
    line-height: initial;
    &:after{
        content: url('../../assets/images/angle-right-grey.svg');
        display: inline-block;
        width: 8px;
        margin-left: 8px;
        margin-bottom: -1px;
        vertical-align: middle;
    }
    &:hover{
        &:after{
            content: url('../../assets/images/angle-right-red.svg');
        }
    }
}
@include media-breakpoint-only(xs) {
    .btn-angle-right{
        align-items: initial;
        &:after{
            margin-top: 1px;
        }
    }
    .btn-group{
        display: flex;
        .btn-outline{
            font-weight: 400;
            padding: .6rem .4rem;
            font-size: 0.9rem;
        }
    }
}