.card-deck{
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    .card{
        margin: 0 1rem;
    }
    &.cards-per-3{
        flex-wrap: wrap;
        .card{    
            flex-basis: 100%;
            max-width: calc(33.33% - 2rem);
            margin-bottom: 2rem;
        }
    }
    &.cards-per-2{
        flex-wrap: wrap;
        .card{    
            flex-basis: 100%;
            max-width: calc(50% - 2rem);
            margin-bottom: 2rem;
        }
    }
}
.border-0{
    .card{
        border: 0;
        &:hover{
            border: 0;
        }
    }
}
@include media-breakpoint-only(xs) {
    .card-deck{
        margin-left: 0;
        margin-right: 0; 
        flex-wrap: wrap;
        flex-direction: column;
        .card{    
            flex-basis: 100%;
            max-width: none !important;
            margin: 0;
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    } 
    &.border-0{
        .card{
            padding: 0;
            margin: 0 0 2rem;
            border: 0;
            // .card-content{
            //     padding: 1rem 1.5rem 0;
            // }
        }
    }
}

.card{
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    position: relative;
    flex: 1;
    margin-bottom: 1.5rem;
    padding:0;
    border: 1px solid $gray-400;
    border-radius: 5px;
    overflow: hidden;
    background-color: $white;
    transition: background-color 0.2s ease;
    &.border-0{
        border: 0;
        &:hover{
            border: 0
        }
    }
    &:hover{
        border: 1px solid $gray-500;
        img{
            transform: scale(1.05);
        }
    }
    .card-content{
        padding: 1.5rem;
        flex: 1 1 auto;
        h2, h3{
            font-size: 1.1rem;
            font-weight: 700;
        }
        p{
            margin-bottom: .5rem;
        }
        ul{
            padding-left: 20px;
            margin-bottom: 0;
        }
        &.card-content-p-lg{
            padding: 2.2rem 2.5rem;
        }
        &.card-content-p-sm{
            padding-top: 1rem;
            padding-bottom: 0;
            h2, h3{
                margin-bottom: .5rem;
                font-size: 1.0rem;
                font-weight: 400;
            }
        }

    }
    a{
        // text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
    .stretched-link{        
        padding: 0 1.5rem 1rem;
        text-decoration: none;
        font-weight: 700;
        color: #575757 ;
        &:hover{
            color: $color2;
        }
        &:before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            pointer-events: auto;
            content: "";
            background-color: transparent;
            order: 2;
        }
        &:after{
            position: relative;
            content: url('../../assets/images/angle-right-grey.svg');
            display: inline-block;
            width: 8px;
            margin-left: 8px;
            margin-bottom: -2px;
            vertical-align: middle;
        }
        &:hover{
            text-decoration: none;
            &:after{
                content: url('../../assets/images/angle-right-red.svg');
            }
        }
    }
    .image{
        overflow: hidden;
        text-align: center;
    }
    img{
        transition: all 0.2s ease-in-out;
    }

}
@include media-breakpoint-only(xs) {
    flex-direction: column;
    .card{
        flex-basis: 100%;
        max-width: none !important; 
        margin-bottom: 1.5rem;
    }
}
@include media-breakpoint-up(sm) {
    &.card--filter{
        border:none;
        &:hover{ border:none; }
        .btn-filter{
            display: none;
        }
        .navbar-filter > div{ padding: 0 !important;}
    }
}

// EXTENTIONS

.cards.jobs{
    .card{
        border:none;
        background-color: $color4;
        text-align: center;
        .card-content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 3rem;
            align-items: center;
        }
        h2, h3{
            font-weight: 300;
            font-size: 1.75rem;
            margin-bottom: 2.0rem;
        }
    }
}