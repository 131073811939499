.arrow-right{
    display: inline;
    color: $color1;
    &:before{
        content: url('../../assets/images/arrow-right.svg');
        vertical-align: middle;
        margin-right: 4px;
        line-height: 0;
    }
}
.arrow-right-white{
    display: inline;
    &:before{
        content: url('../../assets/images/arrow-right-white.svg');
        vertical-align: middle;
        margin-right: 4px;
        line-height: 0;
    }
}

.icons-before{
    padding: 0;
    list-style: none;
    li{
        margin-bottom: 1rem;
        font-weight: 600;
        &:before{
            vertical-align: middle;
            margin-right: 9px;
            line-height: 0;
        }
    }
    .ico-check-green:before{ content: url('../../assets/images/ico-check-green.svg');}
    .ico-check-circle:before{ content: url('../../assets/images/ico-check-circle.svg');}
    .ico-payment:before{ content: url('../../assets/images/ico-payment.svg');}
    .ico-aanpak:before{ content: url('../../assets/images/ico-aanpak.svg');}
    .ico-enveloppe:before{ content: url('../../assets/images/ico-enveloppe.svg');}
    .ico-phone:before{ content: url('../../assets/images/ico-phone.svg');}
}