.component--hero{
    color: $color2;
    position: relative;
    text-align: center;
    .caption{
        position: absolute;
        height: 100%;
        width: 90%;
        padding-left: 42%;
        padding-top: 7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        h1, .h1{
            margin: 0;
        }
    }
    @include media-breakpoint-down(md) {
        .caption{
            padding-top: 5rem;
            h1, .h1{
                font-size: 1.3rem;
            }
        }
    }
    @include media-breakpoint-only(xs) {
        .caption{
            padding-top: 1rem;
            h1, .h1{
                font-size: 1.0rem;
            }
        }
    }

    &-slider{
        .overlay{
            position: absolute;
            top: 0;
            left:0;
            right: 0;
            height: 90%;
            display: flex;
            align-items: flex-end;
            .container{
                padding: 0;
            }
            .caption{
                position: relative !important;
            }
        }
        @include media-breakpoint-up(xl) {
            img{
                width: 100%;
            }
        }
        @include media-breakpoint-only(xs) {
            .overlay{
                // height: 100%;
                width: 90%;
                align-items: flex-end;
                .caption{
                    // margin-bottom: -10px;
                }
            }
            .slick-slide img{
                max-width: 200%;
                // margin-left: -100%; // waar was dit voor nodig??? Nu uitgeschakeld omdat images niet werden getoond....
            }
        }
    }
}