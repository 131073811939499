.container-search{
	position: relative;
	display: flex;
}
.search-open{
	width: 40px;
	margin-right: 5px;
	margin-top:2px;
	// background: url('../../assets/images/loupe-green.svg') no-repeat center;
	background: no-repeat center url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02LjQgMS42QzMuNzQ5MDMgMS42IDEuNiAzLjc0OTAzIDEuNiA2LjRDMS42IDkuMDUwOTcgMy43NDkwMyAxMS4yIDYuNCAxMS4yQzkuMDUwOTcgMTEuMiAxMS4yIDkuMDUwOTcgMTEuMiA2LjRDMTEuMiAzLjc0OTAzIDkuMDUwOTcgMS42IDYuNCAxLjZaTTAgNi40QzAgMi44NjUzOCAyLjg2NTM4IDAgNi40IDBDOS45MzQ2MiAwIDEyLjggMi44NjUzOCAxMi44IDYuNEMxMi44IDcuODc4OTcgMTIuMjk4MyA5LjI0MDc3IDExLjQ1NTkgMTAuMzI0NUwxNS43NjU3IDE0LjYzNDNDMTYuMDc4MSAxNC45NDY3IDE2LjA3ODEgMTUuNDUzMyAxNS43NjU3IDE1Ljc2NTdDMTUuNDUzMyAxNi4wNzgxIDE0Ljk0NjcgMTYuMDc4MSAxNC42MzQzIDE1Ljc2NTdMMTAuMzI0NSAxMS40NTU5QzkuMjQwNzcgMTIuMjk4MyA3Ljg3ODk3IDEyLjggNi40IDEyLjhDMi44NjUzOCAxMi44IDAgOS45MzQ2MiAwIDYuNFoiIGZpbGw9IiM1NzU3NTciLz4KPC9zdmc+Cg==);
	@include media-breakpoint-down(lg) {
		display: none;
	}
}
.searching{
	position: relative;
	height: 40px;
	.search-inline, .search-inline-mob {
		display: flex;
		align-items: center;		
		position: absolute;
		z-index: 9999;
		width: 100%;
		left: 0;
		padding:  0;
		// top: -300px;
		opacity: 0;
//		visibility: hidden; // uitschakelen anders werkt focus niet (toegevoegd via script) 
		transition: all .5s ease-in-out;
	}
	.search-inline{
		background: $white;
		position: absolute;
		left: auto;
		right: -3px;
		width: 0;
		overflow: inherit;
		&.search-visible {
			width: 500px;	
			// top: 0;
			opacity: 1;
			visibility: visible;
			// animation: fadeInRight 0.4s ease-in-out;
		}
	}
	.search-visible-mob {
		background-color: $color1;
		padding: 0;
		padding-top: 20px;
		padding-bottom: 40px;
		opacity: 1;
		visibility: visible;
		// animation: fadeInRight 0.4s ease-in-out;
		button[type="submit"], input[type="submit"]{
			width:40px;
			right: 0;
		}
	}

	&.search-download{
		z-index: 1;
		height: initial;
		font-size: 14px;
		.form-control{ font-size: 14px;}
		.search-inline{
			position: relative;
			right: initial;
			height: inherit !important;
			width: 100%;
			opacity: 1;
			position: relative;
			input, button{
				padding: .7rem .9rem;
				height: inherit;
			}
			button, input[type='button']{
				background-color: #F4F5F4 !important;
				background: no-repeat center center url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02LjQgMS42YTQuOCA0LjggMCAxMDAgOS42IDQuOCA0LjggMCAwMDAtOS42ek0wIDYuNGE2LjQgNi40IDAgMTExMS40NTYgMy45MjVsNC4zMSA0LjMxYS44LjggMCAwMS0xLjEzMiAxLjEzbC00LjMxLTQuMzFBNi40IDYuNCAwIDAxMCA2LjR6IiBmaWxsPSIjNTc1NzU3Ii8+PC9zdmc+');

			}
		}
	}

	button[type="submit"], input[type="submit"] {
		width: 56px;
		background: no-repeat center 13px url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02LjQgMS42QzMuNzQ5MDMgMS42IDEuNiAzLjc0OTAzIDEuNiA2LjRDMS42IDkuMDUwOTcgMy43NDkwMyAxMS4yIDYuNCAxMS4yQzkuMDUwOTcgMTEuMiAxMS4yIDkuMDUwOTcgMTEuMiA2LjRDMTEuMiAzLjc0OTAzIDkuMDUwOTcgMS42IDYuNCAxLjZaTTAgNi40QzAgMi44NjUzOCAyLjg2NTM4IDAgNi40IDBDOS45MzQ2MiAwIDEyLjggMi44NjUzOCAxMi44IDYuNEMxMi44IDcuODc4OTcgMTIuMjk4MyA5LjI0MDc3IDExLjQ1NTkgMTAuMzI0NUwxNS43NjU3IDE0LjYzNDNDMTYuMDc4MSAxNC45NDY3IDE2LjA3ODEgMTUuNDUzMyAxNS43NjU3IDE1Ljc2NTdDMTUuNDUzMyAxNi4wNzgxIDE0Ljk0NjcgMTYuMDc4MSAxNC42MzQzIDE1Ljc2NTdMMTAuMzI0NSAxMS40NTU5QzkuMjQwNzcgMTIuMjk4MyA3Ljg3ODk3IDEyLjggNi40IDEyLjhDMi44NjUzOCAxMi44IDAgOS45MzQ2MiAwIDYuNFoiIGZpbGw9IiNmZmZmZmYiLz4KPC9zdmc+Cg==);
		background-color: #575757;
		border: 0px;
		padding: .6rem .75rem .4rem;
		text-indent: 5000px;
		cursor: pointer;
		&:hover{
			// background: rgba($white,0.1);
		}
		&:focus{
			outline: 0;
		}
	}
	form{ width: 100%; } // op mobile raakt de input ansders niet op 100%

	::placeholder, ::-webkit-input-placeholde, :-moz-placeholder, ::-moz-placeholder , :-ms-input-placeholder { /* Internet Explorer 10+ */ color: #bbb !important; }

	input.form-control {
		border:0 !important;
	}

	.search-close {
		padding: .5rem .75rem;
		color: $gray-600;
		text-align: center;
		display: table;
		text-decoration: none;
	}
	.search-close-mob {
		position: absolute;
		top: 0;
		right: -40px;
		color: $gray-600;
		text-align: center;
		display: table;
		text-decoration: none;
	}

	.search-close i, .search-close-mob i,
	.search-close .ico-close, .search-close-mob .ico-close{
		display: table-cell;
		vertical-align: middle;
	}

	.ico-close {
		display: block;;
		width: 25px;
	}
	.ico-close span {
		content: "";
		display: block;
		width: 25px;
		height: 2px;
		margin: 4px 0;
		background:$color1;
	}
	.ico-close span:first-child {
		transform: rotate(-45deg) translateY(4px) translateX(-2px);
	}
	.ico-close span:nth-child(2) {
		transform: rotate(45deg) translateY(-2px) translateX(0px);
	}
}
.search-mobile{
	display: none;
}
.search-results{
	h2{
		margin-bottom: 1.8rem;
		font-size: 1.6rem;
	}
	h3{
		margin-bottom: 1.4rem;
		font-size: 1.2rem;
	}
	&-item{
		margin-bottom: 2.5rem;
	}
	&-subitem{
		// border-bottom: 1px solid $gray-400;
		// padding-bottom: 2rem;
		margin-top: 2rem;
		// margin-bottom: 2rem;
		// &:last-child{ 
		// 	border-bottom: none;
		// 	padding-bottom: 0;
		// 	margin-bottom: 0;
		// }
	}
}
@include media-breakpoint-down(md) {
	.search-mobile{
		display: flex;
		margin: 2rem 1rem 0;
		width: 100%;
		::placeholder, ::-webkit-input-placeholde, :-moz-placeholder, ::-moz-placeholder , :-ms-input-placeholder { 
			//  color: #eee !important; 
			 font-weight: 300; 
			 font-size: 0.9rem; 
		}
		input.form-control {
			padding-left: 0;
			padding: 1rem;
			width: 100%;
			height: 50px;
			border-radius: 5px 0 0 5px !important;
			outline: none;
		}
		button[type="submit"], input[type="submit"] {
			width: 56px;
			background: no-repeat center center url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02LjQgMS42QzMuNzQ5MDMgMS42IDEuNiAzLjc0OTAzIDEuNiA2LjRDMS42IDkuMDUwOTcgMy43NDkwMyAxMS4yIDYuNCAxMS4yQzkuMDUwOTcgMTEuMiAxMS4yIDkuMDUwOTcgMTEuMiA2LjRDMTEuMiAzLjc0OTAzIDkuMDUwOTcgMS42IDYuNCAxLjZaTTAgNi40QzAgMi44NjUzOCAyLjg2NTM4IDAgNi40IDBDOS45MzQ2MiAwIDEyLjggMi44NjUzOCAxMi44IDYuNEMxMi44IDcuODc4OTcgMTIuMjk4MyA5LjI0MDc3IDExLjQ1NTkgMTAuMzI0NUwxNS43NjU3IDE0LjYzNDNDMTYuMDc4MSAxNC45NDY3IDE2LjA3ODEgMTUuNDUzMyAxNS43NjU3IDE1Ljc2NTdDMTUuNDUzMyAxNi4wNzgxIDE0Ljk0NjcgMTYuMDc4MSAxNC42MzQzIDE1Ljc2NTdMMTAuMzI0NSAxMS40NTU5QzkuMjQwNzcgMTIuMjk4MyA3Ljg3ODk3IDEyLjggNi40IDEyLjhDMi44NjUzOCAxMi44IDAgOS45MzQ2MiAwIDYuNFoiIGZpbGw9IiNmZmZmZmYiLz4KPC9zdmc+Cg==);
			background-color: #575757;
			// border-color: transparent;
			border:none;
			margin-top: 0;
			margin-bottom: 0;
			border-radius: 0 5px 5px 0;
			// padding: 1rem .75rem .4rem;
			// text-indent: 5000px;
			cursor: pointer;
			&:hover{
				background-color: #575757;
			}
			&:focus{
				outline: 0;
			}
		}
	}
}