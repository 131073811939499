$color1:#616365;
$color2:#E00034;
$color3:#E8953E; // orange
$color4:#F6F6F6; // licht grijs

$white: #ffffff;
$light:#F7F7FB; // licht grijs
$dark: #333333; // dark grey

.color1{ color:$color1!important;}
.color2{ color:$color2!important;}
.color3{ color:$color3!important;}
.color4{ color:$color4!important;}
.white{ color:white!important;}

.bgcolor1{ background-image: none!important; background:$color1!important; color:white; h2, h3, a{color:white;}}
.bgcolor2{ background-image: none!important; background:$color2!important; color:white; h2, h3, a{color:white;}; .card{background-color: transparent;}}
.bgcolor3{ background-image: none!important; background:$color2!important; color:white; h2, h3, a{color:white;}; .card{background-color: transparent;}}
.bgcolor4{ background-image: none!important; background:$color4!important; }
.bgwhite{ background-image: none!important; background:white!important; }

$greyGradient:linear-gradient(218deg,#f2f2f2 10.66%,#ffffff 49.05%)!important;
$whiteGradient:linear-gradient(90deg,rgba(255,0,0,0) 25%,rgba(255,0,0,0.5) 75%,rgba(255,0,0,1) 100%)!important;

.bt-1{
	border-top: 1px solid #E2E5E6;
}

img{
	max-width: 100%;
	height: auto;
	image-rendering: -webkit-optimize-contrast;
}


// VARIA
.flex-basis-100{
	flex-basis: 100% !important;
}
.b-dark{
	border:1px solid #C3CBCD !important;
}
.b-dark-grand-children{
	> div:first-child{
		> div{
			border:1px solid #575757;
			border-radius: 5px 5px 0 0;
			overflow: hidden;
			border-bottom: none;
		}
	}
	> div:last-child{
		> div{
			border:1px solid #575757;
			border-radius: 0 0 5px 5px;
			border-top: none;
		}
	}
}
.b-radius-5{
	border-radius: 5px;
	img{
		border-radius: 5px;
	}
}
// @include media-breakpoint-down(md) {
// 	.b-dark-grand-children{
// 		.image img{
// 			object-fit: cover;
// 			height: fit-content;
// 		}
// 	}
// }
@include media-breakpoint-up(md) {
	.b-dark-grand-children{
		> div:first-child{
			> div{
				border:1px solid #575757;
				border-radius: 5px 0 0 5px;
				overflow: hidden;
				border-right: none;
			}
			img{
				border-radius: 5px 0 0 5px;
				overflow: hidden;
			}
		}
		> div:last-child{
			> div{
				border:1px solid #575757;
				border-radius: 0 5px 5px 0;
				border-left: none;
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.b-radius-5{
		> div:first-child{
			> div{
				border-radius: 5px 0 0 5px;
				overflow: hidden;
			}
			img{
				border-radius: 5px 0 0 5px;
				overflow: hidden;
			}
		}
		> div:last-child{
			> div{
				border-radius: 0 5px 5px 0;
				overflow: hidden;
			}
			img{
				border-radius: 0 5px 5px 0;
				overflow: hidden;
			}
		}
	}
	.b-radius-leftside-5{
		border-radius: 5px 0 0 5px;
		overflow: hidden;
	}
	.b-radius-rightside-5{
		border-radius: 0 5px 5px 0;
		overflow: hidden;
	}
}