// https://codepen.io/tobiasdev/pen/GjorQJ
.toast-container {
	// width: 90%;
	// max-width: 580px;
	// margin: 0 auto;
	margin-top: .5rem;

	// &[class*="toast-pos-"] {
	// 	position: absolute;
	// 	padding: 10px;
	// }
	// &.toast-pos-top {
	// 	top: 0;
	// }
	// &.toast-pos-right {
	// 	right: 0;
	// }
	// &.toast-pos-bottom {
	// 	bottom: 0;
	// }
	// &.toast-pos-left {
	// 	left: 0;
	// }
	.toast {
		display: none;
		padding: 20px;
		background: #eeeeee;
		color: #333333;
		background: rgba(green,0.7);
		color: #FFFFFF;
		p{
			margin: 0;
		}
		.close-toast {
			float: right;
			text-decoration: none;
			color: #ffffff;
			vertical-align: middle;
		}
	}
}


