.c-dropdowns{
    display: flex;
    align-items: center;
    // margin: 0 -0.8rem 2rem;
    @include media-breakpoint-only(xs) {
        flex-wrap: wrap;
        .c-dropdown{
            &__wrapper{
                margin-bottom: 1rem;
            }
        }
    }
}
.c-dropdown {
    width: 100%;
    position: relative;
    &__wrapper{
        flex: 1;
        margin-left: .8rem;
        margin-right: .8rem;
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 100%;
        border-right: 1px solid $color1;
        border-bottom: 1px solid $color1;
        border-left: 1px solid $color1;
        li { 
            width: 100%;
            display: flex;
            &:before {
                display: none !important;
            }
            label {
                width: 100%;
                padding: 0.5rem 1rem;
                transition: all 0.3s ease-out;
                cursor: pointer;
                &:hover{
                    background-color: darken($white, 5%);
                    color: $color1;
                }
            }
            input {
                display: none; 
            }
        }
    }
    &__inner {
        display: none;
        position: absolute;
        background-color: $white;
        z-index: 3;
        width: 100%;
        height: 0;
        &.active {
            display: block;
            height: auto;
        }
        span {
            font-size: 0.9rem;
            vertical-align: text-top;
            margin-top: -0.2rem;
            display: inline-block;
        }
    }
    &__selected {
        cursor: pointer;
        width: 100%;
        padding-right: 70px;
        padding-left: 0.8rem;
        height: 40px;
        background: $white url("../images/angle-top-grey.svg")
            95% center no-repeat;
        // background: $white url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAHCAYAAAAxrNxjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABqSURBVHgBdY+xFYAwCERDiiRlRnAVN0jJGLqJG9iyhSO4gptgzvdoeHjV8Tl4QGOM3lrrIvKkQNbPpZRTVS9mXnwIrNZ6z/6Rp3ZAH4YHgyeijQK42qDVOIuiDSYLfd7f5DelPyGMTz1/AeH5OTlJA/n/AAAAAElFTkSuQmCC")
        //     95% center no-repeat;
        border: 1px solid $color1;
        border-radius: 5px;
        font-weight: normal;
        display: flex;
        align-items: center;
        position: relative;
        span {
            font-size: 0.9rem;
            vertical-align: text-top;
            margin-top: -0.2rem;
            display: inline-block;
        }
    }
}
