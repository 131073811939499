.navbar{
    padding: 0;
    flex-grow: 1; //////////////////////////
}
.p-nav{
    flex-direction: row;
    flex-wrap: wrap;
}
.p-nav__top, .p-nav__main{
    flex-basis: 100%;
    ul{
        justify-content: flex-end;
    }
}

.p-nav {
    flex-direction:column;
    align-items: flex-end;
    &__top{
        margin-bottom: 1.0rem;
        background: $white;
        position: relative;
        border-radius: 0 5px 5px 0;
        &:after{
            content: '';
            position: absolute;
            bottom: -4px;
            height: 3px;
            width: 100%;

            margin: auto;
            border-bottom-left-radius: 40% 4px;
            border-bottom-right-radius: 40% 2px;
            background:linear-gradient(90deg, rgba(153,153,153,0) 0%,rgba(153,153,153,0.07) 95%,rgba(153,153,153,0) 100%);
        }
        width: 100%;
        ul {
            position: relative; // for search
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                margin: 0 0.8rem;
                // outline: 1px solid red !important;
                a {
                    display: block;
                    text-decoration: none;
                    // padding: 0.5rem 0;
                    padding: 0.4rem 0 ;
                    color: #2F2F2F;
                    transition: all .5s ease;
                    font-size: 0.9rem;
                    &:hover {
                        color: $color2;
                    }
                }
                &:first-child a {
                    padding-left: 0;
                }
                &:last-child{
                    margin: 0;
                    display: flex;
                    a:not(.search-open):not(.search-close) {
                        position: relative;
                        z-index: 9999;
                        background: $color2;
                        color: white;
                        font-weight: 700;
                        border: none;
                        border-radius: 5px;
                        // padding: 0.5rem 0;
                        padding: 0.6rem 1rem 0.4rem;
                        &:hover {
                            border: none;
                            background: darken($color2,5%) !important;
                        }
                    }
                }
                &.active {
                    a:not(.search-open):not(.search-close) {
                        color: $color2;
                    }
                }
            }
        }
        @include media-breakpoint-down(lg) {
            ul{
                li{
                    margin: 0 0.7rem; 
                }
            }
        }
    }
    &__main {
        // margin-bottom: 1.0rem;
        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                margin: 0 1.1rem;
                a {
                    display: block;
                    text-decoration: none;
                    padding: 0.3rem 0;
                    color: #2F2F2F;
                    transition: all .5s ease;
                    font-size: 19px;
                    &:hover {
                        color: $color2;
                    }
                }
                &:first-child a {
                    padding-left: 0;
                }
                &:last-child{
                    margin-right: 0;
                }
                &.active {
                    a {
                        color: $color2;
                    }
                }
            }
        }
        @include media-breakpoint-down(lg) {
            ul{
                li{
                    margin: 0 0.7rem; 
                }
            }
        }
    }
}

@include media-breakpoint-only(lg) {  // tablet portrait
    .navbar{
        .navbar-toggler{
            display: none;
        }
        .p-nav{
            &__top{
                ul{
                    flex-wrap: nowrap;
                }
            }
        }
    }
}
@include media-breakpoint-down(md) { 
    .navbar{
        position: absolute;
        right: 0;
        // top: 2rem;
        top: 1.1rem;
        width: 100%;
        z-index: 999;
        justify-content: flex-end;
        padding: 0 !important;
        .navbar-toggler{
            border: 0;
            &:focus{
                outline: none !important;
            }
            &.active{
                .navbar-toggler-icon{
                    background: transparent;
                    &:before{
                        transform: translateX(4px) translateY(-2px) rotate(45deg);
                    }
                    &:after{
                        transform: translateX(4px) translateY(4px) rotate(-45deg);
                    }
                }
            }
        }
        .navbar-toggler-icon{
            position: relative;
            background-image: none;
            display: inline-block;
            height: 2px;
            width: 25px;
            top: -2px;
            // margin-left: 4px;
            background: $gray-600;
            transition: background 0.3s 0.3s;
            &:before{
                position: absolute;
                display: inline-block;
                height: 2px;
                width: 25px;
                background: $gray-600;
                top: -6px;
                content: '';
                right: 0px;
                transform: translateZ(0);
                backface-visibility: hidden;
                transform-origin: 0% 50%;
                transition: all 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
            }
            &:after{
                position: absolute;
                display: inline-block;
                height: 2px;
                width: 25px;
                background: $gray-600;
                top: 6px;
                content: '';
                right: 0px;
                transform: translateZ(0);
                backface-visibility: hidden;
                transform-origin: 0% 50%;
                transition: all 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
            }
        }
        .navbar-collapse{
            margin-top: 2.2rem;
            background: $color4;
            height: calc( 100vh - 90px);
            overflow: scroll;
        }
        .p-nav {
            box-shadow: 0 10px 10px rgba($color1,.2);
            &__top{
                background-color: $color4;
                ul{
                    li{
                        a{
                            border: none;
                            padding: 0.8rem .5rem;
                        }
                        &:last-child{
                            a{
                                margin-top: .5rem;
                                background: $color3;
                                border-left: 2px solid $white;
                                // padding: .6rem 1rem .4rem;
                                padding: 0.5rem 1rem 0.6rem !important;
                            }
                        }
                        &.active a{
                            // color: $white;
                            border:none;
                        }
                    }
                }
            }
            &__main{
                padding: 0rem 15px 2.5rem;
                ul{
                    flex-direction: column;
                    align-items: center;
                    li{
                        text-align: center;
                        width: 100%;
                        margin: 0 0 .4rem 0;
                        order: 2;
                        &:last-child{
                            order: 1;
                        }
                        a{
                            display: inline-block;
                            margin-bottom: .8rem;
                            font-weight: 500;
                            font-size: 1.3rem;
                        }
                        a.search-open{
                            display: none;
                        }
                        // &.active, &:hover{
                        //     a{
                        //         // color: $white;
                        //         border-bottom: 1px solid $white;
                        //     }
                        // }
                    }
                }
            }
        }
        &:focus{
            outline: none !important;
        }
    }
}
@include media-breakpoint-down(md) {
    .navbar{
        .p-nav {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            &__top{
                flex: 1;
                margin-top: 2rem;
                margin-bottom: 2rem;
                border-right: 1px solid $gray-600;
                border-radius: 0;
                &:after{
                    display: none;
                }
                ul{
                    display: block;
                    margin-left: 1rem;
                    li{
                        padding:0;
                        margin: 0;
                        a{
                            padding:.5rem 0;
                            font-size: 1rem;
                        }
                        &:first-child a{
                            padding-top: 0;
                        }
                    }
                }
                .container-search{
                    display: none;
                }
            }
            &__main{
                // order: 1;
                flex: 1.5;
                margin-top: 2rem;
                margin-bottom: 2rem;
                ul{
                    display: block;
                    margin-left: 1.5rem;
                    li{
                        padding: 0 1.5rem 0 0;
                        margin: 0;
                        text-align: left;
                        // border-bottom: 1px solid $gray-400;
                        a{
                            padding: 1.0rem 0 0rem;
                            margin-top: -.5rem; // top-aligneren met lijn
                            font-size: 1.4rem;
                        }
                        &:first-child a{
                            padding-top: 0 !important;
                        }
                    }
                }
            }
        }
    }
}