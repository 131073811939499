.p-footer {
    background: $color1;
    font-size: 0.9rem;
    .container{
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    ul{
        display: flex;
        li{
            margin-right: 1.5rem;
        }
    }
    .logo{
        margin-bottom: -2rem;
        background: #fff;
        padding: 10px;
        img{
            width: 50px;
        }
    }
    a{
        color: white;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
    ul{
        padding: 0;
        list-style: none;
        margin-bottom: 0;
    }
    ul.socials{
        display: flex;
        margin-top: 1rem;
        flex-wrap: wrap;
        li{
            margin: 0 1.0rem;
            a{
                img{
                    transition: all .1s ease;
                }
                &:hover{
                    img{
                        transform: scale( 1.4);
                    }
                }
            }
        }
    }
    .logo{
        transition: all .1s ease;
        &:hover{
            transform: scale( 1.05);
        }
    }
    @include media-breakpoint-only(xs) { 
        .container{
            flex-wrap: wrap;
            ul{
                margin: 0 !important;
                display: flex;
                flex-wrap: wrap;
                li:first-child{
                    flex: 1 0 100%;
                    margin-bottom: .3rem;
                }
            }
        }
        ul.socials{
            margin: 1rem 0;
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            li:first-child{
                flex:1;
            }
            li{
                margin: 0 .5rem;
            }
        }
        .logo{
            display: inline-block;
            margin-top: 1rem;
            margin-bottom: -1rem;
            margin-right: 2.5rem;
        }
    }
}