
// FORMS
.form{
    label{
        color: $color1;
        font-weight: 500;
        font-size: 0.9rem;
    }
}
.form-group{
    margin-bottom: 1.5rem;
}
input[type=text],
input[type=email],
input[type=password],
textarea,
select{
    border: none;
    border-radius: 0 !important;
    border: none !important;
    background-color: #F4F5F4 !important;
    padding: .375rem .75rem; 
    &:focus{
        outline: none; box-shadow: none;
        background-color: #F4F5F4;  
    }
}
.form-control:focus{
    background-color: #F4F5F4;  
}

.c-form__label{
    font-weight: 500;
    color: $color1;
}

.navbar, .bgcolor4{
    input[type=text],
    input[type=email],
    input[type=password],
    textarea,
    select{
        border: none;
        border-radius: 0 !important;
        border: 1px solid $gray-300 !important;
        background-color: $white !important;
        padding: .375rem .75rem; 
        &:focus{
            outline: none; box-shadow: none;
            background-color: $white;  
            box-shadow: none;
        }
    }
    .form-control:focus{
        background-color: $white;  
    }
}

.custom-select{
    background: no-repeat 98% center url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1TDkgMSIgc3Ryb2tlPSIjNzM3QjdEIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=');
    &:hover{
        cursor: pointer;
    }
    &:focus{
        outline: none;
        box-shadow: none;
    }
}

// CUSTOM BOOTSTRAP CHECKBOXES
.custom-control{
    margin-bottom: 0.4rem;
}
.custom-control-input:checked~.custom-control-label:before {
    color: #fff;
    border-color: $color1;
    background-color: $color1;
    border-radius: 0;
}
.custom-checkbox .custom-control-label:before {
    border-radius: 0;
}


.document--group{
    border-bottom: 1px solid #ccc;
    padding-bottom: 1.8rem;
    margin-bottom: 1.7rem;
    &:last-child{
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}