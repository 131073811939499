.slider{
    &.quotes{
        display: flex;
        .slick-track{
            background: $color2;
            display: flex;
            align-items: center;
        }
        .slick-slide{
            padding: 2rem;
            color: $white;
            text-align: center;
            a, h2, h3{
                color: $white;
            }
            &:focus{
                outline: none;
            }
        }
        .quote--{
            &title{
                font-size: 2.4rem;
                font-weight: 900;
            }
            &author{
                background: $white;
                display: inline-block;
                margin-top: 0.5rem;
                padding: 0.5rem 1rem;
                color: $color2;
                font-weight: 400;
            }
        }
        .slick-dots{
            bottom: 30px;
            li{
                display: inline-flex;
                margin: 0;
                button{
                    &:before{
                        border:0 solid transparent;
                        color:$white !important
                    }
                }
            }
            li button:before{
                color:$white !important;
                &.slick-active button:before{
                    color: white !important;
                }
            }
        }
        @include media-breakpoint-down(sm){
            .slick-dots{
                li{
                    height: 40px;
                    width: 40px;
                    button{
                        height: 40px;
                        width: 40px;
                        &:before{
                            font-size: 72px;
                        }
                    }
                }
            }
            .slick-slide{
                padding-bottom: 4.5rem;
            }
            .slick-dots{
                margin: 0;
                bottom:15px;
            }
        }
        @include media-breakpoint-only(xs){
            .quote--{
                &title{
                    font-size: 1.8rem;
                }
            }

        }


    }
    .slick-slide{
        position: relative;
        .caption {
            display: inline-block;
            position: absolute;
            z-index: 1;
            left: 0;
            bottom: 18%;
            background-color: #555;
            border-radius: 5px;
            font-size: 20px;
            color: #fff;
            padding: 0.6rem 1.2rem;
        }
        @include media-breakpoint-only(xs) {
            .caption{
                font-size: 15px;
                border-radius: 0 5px 5px 0;
            }
        }
    }
}