.domino{
    .item{
        display: flex;
        margin-bottom: 4rem;
        .container > div{
            flex-basis: 50%;            
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: baseline;
            .btn{
                margin-top: 1rem;
            }
        }
        h2{
            font-size: 1.6rem;
        }
    }

    @include media-breakpoint-up(sm) {
        .item{
            .container{
                display: flex;
                align-items: center;
                padding: 0;
                > div:first-child{ // this is the text div
                    padding: 3rem;
                }
                > div:last-child{ // this is the image div
                    padding: 0;
                }
                // img{
                //     height: 460px;
                //     object-fit: cover;
                // }
            }
            &:nth-child(even){
                .container{
                    background-color: $white;
                    border:1px solid #ccc;
                    border-radius: 5px;                    
                    img{
                        border-radius: 5px 0 0 5px;
                    }
                    > div:nth-child(1){
                        order:2;
                    }
                    > div:nth-child(2){
                        order:1;
                    }
                }
            }
            &:nth-child(odd){
                .container{
                    background-color: $color4;
                    border-radius: 5px;
                    > div:nth-child(2){
                        // background-color: lighten($color4,1.5%);
                        background-color: #fff;
                    }
                    img{
                        border-radius: 0 5px 5px 0;
                    }
                }
            }
        }
    }

    @include media-breakpoint-only(xs) {
        .item{
            margin-bottom: 2rem;
            .container{
                padding-top: 1.5rem;
                padding-bottom: 0;
                padding-left: 0;
                padding-right: 0;
                > div{
                    height: auto;
                    align-items: initial;
                    &:first-child{
                        padding-left: 1.5rem;
                        padding-right: 1.5rem;
                    }
                    &:last-child{
                        text-align: center;
                    }
                }
                > div:first-child{ // this is the text div
                    margin-bottom: 1.5rem;
                }
                > div:last-child{ // this is the image div
                    img{
                        border-radius:0 0 5px 5px;
                    }
                }
            }
            &:nth-child(even){
                .container{
                    background-color: $white;
                    border:1px solid #ccc;
                    border-radius: 5px;    
                    > div:nth-child(2){
                        margin-bottom: 0;
                    }                
                }
            }
            &:nth-child(odd){
                .container{
                    background-color: $color4;
                    border-radius: 5px;
                    > div:nth-child(2){
                        margin-bottom: 0;
                    }
                }
            }
        }
        div.image{
            order:2;
        }
        div.text{
            order:1;
        }
    }
}